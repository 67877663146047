import React, { Component } from 'react';
import history from "../../shared/history";
import Link from 'react-router-dom/es/Link';
import { lastSeenFormat } from '../../shared/commonHelper';

class AgentLeftBar extends Component {

    handleAgentNavigation(searchType, event) {
        this.setState({ activeLeftNav: searchType });
        this.props.handleClickValue(searchType);
    }

    render() {

        const { accountResponse } = this.props;
        let activeLeftNavigation = this.props.activeLeftNavigation;
        let passwordLastUpdatedDate = accountResponse.myAccountResponse.response && accountResponse.myAccountResponse.response.password_last_updated ? accountResponse.myAccountResponse.response.password_last_updated : '';
        let passwordUpdatedDays = lastSeenFormat(passwordLastUpdatedDate);
        let passWordDayText = passwordUpdatedDays.split(' ');
        let user_type_id = accountResponse.myAccountResponse.response && accountResponse.myAccountResponse.response.user_type_id;
        let credit_request = accountResponse.myAccountResponse.response && accountResponse.myAccountResponse.response.is_credit_allowed && accountResponse.myAccountResponse.response.is_credit_allowed.toLowerCase();
        return (
            <div className="profile-widget" history={history}>
                <div className="profile-widget-outer">
                    <div className="profile-card">
                        <div className="profile-dp">
                            {/* <Link to="/agent-info" className="icon icon-pencil edit-icon"></Link> */}
                            <div className="profile-pic">
                                <figure><img src={accountResponse.myAccountResponse.response && accountResponse.myAccountResponse.response.company_logo ? accountResponse.myAccountResponse.response.company_logo : 'assets/images/no-image-icon.png'} alt="" /></figure>
                                <span />
                            </div>
                            <h2>{accountResponse.myAccountResponse.response && accountResponse.myAccountResponse.loading ? '-------' : accountResponse.myAccountResponse.response && accountResponse.myAccountResponse.response.first_name} {accountResponse.myAccountResponse.response && !accountResponse.myAccountResponse.loading ? accountResponse.myAccountResponse.response && accountResponse.myAccountResponse.response.last_name: "------"}</h2>
                            <div className="logout-row">
                                <p>Last Login: <span>{lastSeenFormat(accountResponse.myAccountResponse.response && accountResponse.myAccountResponse.response.last_login)}</span></p>
                                { /* eslint-disable-next-line */}
                                <a href="javascript:void(0)" onClick={() => { this.props.logoutAction() }}>Logout</a>
                            </div>
                        </div>
                    </div>
                    <div className="profile-nav">
                        <ul>

                            <li className={(activeLeftNavigation === "/my-account") ? "active" : ""}>{ /* eslint-disable-next-line */}
                                <Link to="/my-account" onClick={this.handleAgentNavigation.bind(this, 'my-account')}>
                                    <i className="icon icon-user" /><span>My Account</span></Link>
                            </li>
                            <li className={(activeLeftNavigation === "/change-password") ? "active" : ""}>
                                <Link to="/change-password" onClick={this.handleAgentNavigation.bind(this, 'change-password')}>
                                    <i className="icon icon-key" />
                                    <span>Change Password {passWordDayText[1].includes("Days") && passWordDayText[0] > 25 ? <i className="red-text fa fa-exclamation-circle" aria-hidden="true"></i> : ''}</span>

                                </Link>
                            </li>
                            <li className={(activeLeftNavigation === "/my-bookings" || activeLeftNavigation === '/view-flight-booking' || activeLeftNavigation === '/view-hotel-booking' || activeLeftNavigation === '/view-train-booking' || (activeLeftNavigation === "/booking" && this.props.pageUrl === "my-bookings")) ? "active" : ""}>
                                <Link to="/my-bookings" onClick={this.handleAgentNavigation.bind(this, 'my-bookings')}>
                                    <i className="fa fa-exchange" /><span>My bookings</span></Link>
                            </li>
                            <li className={(activeLeftNavigation === "/request-bookings" || activeLeftNavigation === '/request-flight-booking' ||  (activeLeftNavigation === "/booking" && this.props.pageUrl === "request-bookings")) ? "active" : ""}>
                                <Link to="/request-bookings" onClick={this.handleAgentNavigation.bind(this, 'request-bookings')}>
                                    <i className="fa fa-exchange" /><span>Request Cancellation<small className="new-badge badge">New</small></span></Link>
                            </li>
                            <li className={(activeLeftNavigation === "/mark-up-tool" || activeLeftNavigation === '/flight-type-list' || activeLeftNavigation === '/domestic-markup-tool' || activeLeftNavigation === '/international-markup-tool' || activeLeftNavigation === '/hotel-type-list') ? "active" : ""}>
                                <Link to="/mark-up-tool" onClick={this.handleAgentNavigation.bind(this, 'mark-up-tool')}>
                                    <i className="fa fa-percent" /><span>Mark-up Tool</span></Link>
                            </li>
                            <li className={(activeLeftNavigation === '/deposits' || activeLeftNavigation === '/view-deposit' || activeLeftNavigation === '/deposit-request') ? "active" : ""}
                                style={{ display: (user_type_id !== 1) ? 'block' : 'none' }}>
                                <Link to="/deposits" onClick={this.handleAgentNavigation.bind(this, 'deposits')}>
                                    <i className="icon icon-money" /><span>Request Deposit </span></Link>
                            </li>

                            { /*<li className={(activeLeftNavigation === "/credits" || activeLeftNavigation === '/view-credits' || activeLeftNavigation === '/credit-request') ? "active" : ""}
                                style={{ display: credit_request === 'y' ? 'block' : 'none' }}>
                                <Link to="/credits" onClick={this.handleAgentNavigation.bind(this, 'credits')}>
                                    <i className="icon icon-currency" /><span>Request Credit Limit</span></Link>
                            </li>*/}
                            <li className={(activeLeftNavigation === "/bank-detail") ? "active" : ""}>
                                <Link to="/bank-detail" onClick={this.handleAgentNavigation.bind(this, 'bank-detail')}>
                                    <i className="fa fa-bank" /><span>Bank Detail</span></Link>
                            </li>
                            <li className={(activeLeftNavigation === "/account-statement") ? "active" : ""}>
                                <Link to="/account-statement" onClick={this.handleAgentNavigation.bind(this, 'account-statement')}>
                                    <i className="fa fa-list" /><span>Account Statement</span></Link>
                            </li>
                            <li className={(activeLeftNavigation === "/create-report") ? "active" : ""}>
                                { /* eslint-disable-next-line */}
                                <Link to="/create-report" onClick={this.handleAgentNavigation.bind(this, 'create-report')}>
                                    <i className="icon icon-result-file" /><span>Create Report</span></Link>
                            </li>
                            <li className={(activeLeftNavigation === "/booking-calendar" || (activeLeftNavigation === "/booking" && this.props.pageUrl === "booking-calendar") || activeLeftNavigation === "/booking-error" || activeLeftNavigation === "/hotel-booking") ? "active" : ""}>
                                <Link to="/booking-calendar" onClick={this.handleAgentNavigation.bind(this, 'calendar')}>
                                    <i className="icon icon-calendar" /><span>Travel Calendar<small className="new-badge badge">New</small></span></Link>
                            </li>

                            { /*<li className={(activeLeftNavigation === "/top-up" || activeLeftNavigation === "/view-topup" || activeLeftNavigation === "/topup-request") ? "active" : ""}>
                                <Link to="/top-up" onClick={this.handleAgentNavigation.bind(this, 'top-up')}>
                                    <i className="icon icon-currency2" /><span>Top Up</span></Link>
                            </li>*/}
                            {
                                accountResponse.myAccountResponse.response && accountResponse.myAccountResponse.response.is_vendor === 'Y' &&
                                <li className={(activeLeftNavigation === "/vendor"
                                    || activeLeftNavigation === "/add-vendor-booking"
                                    || activeLeftNavigation === "/view-vendor-bookings"
                                    || activeLeftNavigation === "/view-booking-detail"
                                    || activeLeftNavigation === "/edit-vendor-booking") ? "active" : ""}>
                                    { /* eslint-disable-next-line */}
                                    <Link to="/vendor" onClick={this.handleAgentNavigation.bind(this, 'vendor')}>
                                        <i className="icon icon-user" /><span>Vendor</span></Link>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default (AgentLeftBar)